import {
  createStyles,
  Image,
  Container,
  Title,
  Button,
  Group,
  Text,
  List,
  ThemeIcon,
  rem,
  Grid,
  Center,
} from "@mantine/core";
import Lottie from "lottie-react";
import { IconCheck } from "@tabler/icons-react";
import image from "../static/imgHero.png";
import { BrowserView, MobileView } from "react-device-detect";
import StockAni from "../static/#2 Stock Landing pagee.json";

const useStyles = createStyles((theme) => ({
  inner: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: `calc(${theme.spacing.xl} * 2)`,
    paddingBottom: `calc(${theme.spacing.xl} * 4)`,
  },

  content: {
    maxWidth: rem(480),
    marginRight: `calc(${theme.spacing.xl} * 3)`,

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
      marginRight: 0,
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(46),
    lineHeight: 1.4,
    fontWeight: 900,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
    },
  },

  control: {
    [theme.fn.smallerThan("xs")]: {
      flex: 1,
    },
  },

  image: {
    flex: 1,

    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },

  highlight: {
    position: "relative",
    backgroundColor: theme.fn.variant({
      variant: "light",
      color: theme.primaryColor,
    }).background,
    borderRadius: theme.radius.sm,
    padding: `${rem(4)} ${rem(12)}`,
  },
}));

export default function Hero() {
  const { classes } = useStyles();
  return (
    <div>
      <Container>
        {/* <div>
          <ReactECharts
            option={getOption()}
            notMerge={true}
            lazyUpdate={true}
          />
        </div> */}
        <div className={classes.inner}>
          <Grid>
            <Grid.Col xs={4} orderXs={2}>
              {/* <Image src={image} className={classes.image} /> */}

              <Lottie
                animationData={StockAni}
                style={style} /* 
                interactivity={interactivity} */
              />
            </Grid.Col>
            <Grid.Col xs={8} orderXs={1}>
              <div className={classes.content}>
                <Title className={classes.title}>
                  Crafting<span className={classes.highlight}>Tailored</span>{" "}
                  Software Solutions
                </Title>
                <Text color="dimmed" mt="md">
                  <>
                    {/*  <BrowserView>
                      <h1>This is rendered only in browser</h1>
                      

                  </BrowserView>
                  <MobileView>
                      <h1>This is rendered only on mobile</h1>
                  </MobileView> */}
                  </>
                </Text>

                <List
                  mt={30}
                  spacing="sm"
                  size="sm"
                  icon={
                    <ThemeIcon size={20} radius="xl">
                      <IconCheck size={rem(12)} stroke={1.5} />
                    </ThemeIcon>
                  }
                >
                  <List.Item>
                    <b>Tailored Solutions for Your Business:</b> We create
                    bespoke software to perfectly fit your unique needs.
                  </List.Item>
                  <List.Item>
                    <b>Expert Craftsmanship, Unparalleled Results:</b> Our
                    skilled team delivers high-quality, custom-made software
                    that exceeds expectations. in any project
                  </List.Item>
                  <List.Item>
                    <b>Empowering Your Digital Transformation:</b> Unlock your
                    business's full potential with our cutting-edge software
                    expertise.
                  </List.Item>
                </List>
              </div>
            </Grid.Col>
          </Grid>
        </div>
      </Container>
    </div>
  );
}

const getOption = () => {
  return {
    graphic: {
      elements: [
        {
          type: "text",
          left: "center",
          top: "center",
          style: {
            text: "KOTA",
            fontSize: 80,
            fontWeight: "bold",
            lineDash: [0, 200],
            lineDashOffset: 0,
            fill: "transparent",
            stroke: "#000",
            lineWidth: 1,
          },
          keyframeAnimation: {
            duration: 3000,
            loop: true,
            keyframes: [
              {
                percent: 0.7,
                style: {
                  fill: "transparent",
                  lineDashOffset: 200,
                  lineDash: [200, 0],
                },
              },
              {
                // Stop for a while.
                percent: 0.8,
                style: {
                  fill: "transparent",
                },
              },
              {
                percent: 1,
                style: {
                  fill: "black",
                },
              },
            ],
          },
        },
      ],
    },
  };
};

const style = {
  height: 300,
};

const interactivity = {
  mode: "scroll",
  actions: [
    {
      visibility: [0, 0.2],
      type: "stop",
      frames: [0],
    },
    {
      visibility: [0.2, 0.45],
      type: "seek",
      frames: [0, 45],
    },
    {
      visibility: [0.45, 1.0],
      type: "loop",
      frames: [45, 60],
    },
  ],
};
