import React, { useEffect } from "react";
import roi from "../../static/roi.jpg";
import { Text, List } from "@mantine/core";
import {
  IconCurrentLocation,
  IconFlame,
  IconFlag,
  IconCalendarStats,
  IconNotebook,
  IconBuildingCommunity,
} from "@tabler/icons-react";
import About from "./About";
import Curricilum from "./Curricilum";

export default function Roi() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="bg-base-300">
        <About data={data} />
        <Curricilum features={features} />
      </div>
    </div>
  );
}

const data = {
  photo: roi,
  email: "roidjoensen@gmail.com",
  linkedIn: "https://www.linkedin.com/in/r%C3%B3i-dam-joensen-14081699/",
  cvUrl: "https://212.60.126.221/landing_page/roi_cv",
  aboutText: (
    <Text>
      I have a strong educational background in computer science and business
      administration where I hold a Master's degree in both fields from Danmarks
      Tekniske Universitet (DTU) and Copenhagen Business School (CBS),
      respectively. My expertise lies in fields of data science, software
      engineering, analytics and people management. <br /> I've gained extensive
      work experience at Novo Nordisk, where I've held various roles starting as
      a data analyst and moving up the ranks to now being the Director of
      Digital Finance and Infrastructure. I've have been recongnised as a highly
      skilled and dedicated professional with a strong track record of
      delivering innovative and value adding solutions.
    </Text>
  ),
  features: [
    {
      icon: IconCurrentLocation,
      title: "Location:",
      description: "Zurich, CH",
    },
    {
      icon: IconCalendarStats,
      title: "Age:",
      description: "35",
    },
    {
      icon: IconFlag,
      title: "Nationality:",
      description: "Danish",
    },
    {
      icon: IconFlame,
      title: "Interests:",
      description: "Hiking, Finance, Chess",
    },
    {
      icon: IconNotebook,
      title: "Study:",
      description: "DTU & CBS",
    },
    {
      icon: IconBuildingCommunity,
      title: "Employment:",
      description: "Novo Nordisk",
    },
  ],
};

const features = {
  education: [
    {
      degree: " M.Sc. in Computer Science and Engineering",
      school: "Danmarks Tekniske Universitet (DTU) • 2016",
      masterThesis: "Human Mobility Patterns at Large-Scale Events",
    },
    {
      degree: "M.Sc. Business Administration & Information Systems",
      school: "Copenhagen Business School (CBS) • 2016",
      masterThesis: "Occupational Fraud Detection",
    },
    {
      degree: "B.Sc. Business Administration & Information Systems",
      school: "Copenhagen Business School (CBS) • 2013",
      masterThesis: "Optimising Stock Policies with Cross Dependencies",
    },
  ],
  work: [
    {
      company: "Novo Nordisk",
      timeline: "2022 - present",
      position: "Director, Digital Finance & Infrastructure",
      description:
        "Implementing a digital infrastructure and system landscape together with HQ. Ensuring that BI tools and full-stack applications are developed and rolled-out for the IO finance organisation.",
    },
    {
      company: "Novo Nordisk",
      timeline: "2019 - 2022",
      position: "Head of Assurance Intelligence",
      description: (
        <p>
          Leading a team in the use of software engineering and analytics to
          identify opportunities for improvements in the company's financial
          management, and to develop digital tools for the company's global
          organization.
          <ul>
            <li>· Team development, people engagement, talent management.</li>
            <li>
              · Full-Stack development, Advanced analytics, machine learning,
              NLP, process automation.
            </li>

            <li>
              · Examples of digital solutions built and implemented: a automated
              fraud/non-compliance detection system, minimum control system and
              a tool digitalising the entire internal audit process
            </li>
          </ul>
        </p>
      ),
    },
    {
      company: "Novo Nordisk",
      timeline: "2016 - 2019",
      position: "Data Scientist",
      description: (
        <>
          Internal Audit developing fraud detection analytics - Full-Stack
          development/Software Engineering/Data Analytics/Machine Learning &
          Data visualization. Built and implemented different web applications.
          Example of these are:
          <ul>
            <li>
              1. Compliance Data Platform used by the compliance organization
              worldwide. The tool displays multiple different data
              sources/analysis e.g. general ledger, vendor and employee
              expenses.
            </li>
            <li>2. Risk management tool used by the legal & compliance.</li>
            <li>
              3. Document analysis tool, used for document translation and
              automatically risk flagging documents for e.g. off-label promotion
              or missing contract clauses. Mainly used by auditors and the India
              outsourcing unit.
            </li>
          </ul>
        </>
      ),
    },
    {
      company: "Novo Nordisk",
      timeline: "2013 - 2016",
      position: "Data Analyst",
      description:
        " Data Analytics & Data Mining for fraud detection in internal audit.",
    },
    {
      company: "Copenhagen Business School",
      timeline: "2011 - 2015",
      position: "Multiple TA/Instructor positions",
      description: (
        <>
          <List>
            <List.Item>
              <Text color="black" fz="sm">
                · Instructor: Internet of Things (M.Sc. course) - Workshop
                responsible for M.Sc. elective course "Internet of Things".
              </Text>
            </List.Item>
            <List.Item>
              <Text color="black" fz="sm">
                · Instructor: Big Data Management (M.Sc. course) - Teached
                course curriculum: Python for Data Mining - Data reduction and
                visualization - Big data tools and techniques (Nearest
                neighbour, similarity, clustering) - Social media data analysis
                - Data modelling and Predictive modelling - Value creation and
                management of big data.
              </Text>
            </List.Item>
            <List.Item>
              <Text color="black" fz="sm">
                · Instructor: Development of Large Systems, (B.Sc. course) -
                Teached course curriculum: System modelling - various UML
                diagrams - object oriented analysis and design - soft systems
                methodology.
              </Text>
            </List.Item>
            <List.Item>
              <Text color="black" fz="sm">
                · Instructor: Introductionary Programming (B.Sc. course) -
                Teached course curriculum: Java programming.
              </Text>
            </List.Item>
          </List>
          <ul>
            <li></li>
          </ul>
        </>
      ),
    },
    {
      company: "Eik Banki",
      timeline: "2008 - 2013",
      position: "IT Technician/Support",
      description:
        "  Worked as an IT technician at Eik Bank’s HQ in the Faroe Islands. Where I also worked every summer holiday from 2009 to 2013",
    },
  ],

  skills: [
    "Python",
    "JavaScript",
    "React",
    "SQL",
    "NEO4J",
    "Postgres",
    "Java",
    "C#",
    " VBA",
    "Node.js",
    "PowerBI",
    "PowerAutomate",
    "Azure",
    "Google Cloud",
    "Qlikview",
    "SAP",
    "SSIS",
  ],
};
