import React,{useEffect,useState} from 'react';
import './App.css';

import NavBar from './pages/Navbar';
import Hero from './pages/Hero'
import Features from './pages/Features'
import ContactUs from './pages/ContactUs';
import Team from './pages/Team';
import Footer from './pages/Footer'

function App() {
  const [width, setWidth] = React.useState(window.innerWidth);
  useEffect(() => {
    // Update the document title using the browser API
    document.title = `hwelek`;
    window.getComputedStyle(document.body)
    window.addEventListener("resize", () => setWidth(100))
    
    //let pareentDocument = window.parent.document
    //console.log(pareentDocument)
    //var metaTag=pareentDocument.createElement('meta');
    //metaTag.name = "viewport"
    //metaTag.content = "width=1, initial-scale=1"
    //pareentDocument.getElementsByTagName('head')[0].appendChild(metaTag);
  });
  return (
    <div>
   <div className="bg-base-300">
      <NavBar />
      <Hero />
    </div>
    <div className="bg-base-200">
       <Features />
       <ContactUs />
    </div>
    <div className="bg-base-300">
       <Team />
       <span>testes</span>
    </div>
    <div >
      <Footer />
    </div>
    </div>  
  );
}

export default App;


