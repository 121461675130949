import React, { useEffect, useState } from "react";
import "./App.css";
import {} from "@mantine/core";
import {
  Routes,
  Route,
  Link,
  Navigate,
  Outlet,
  useLocation,
} from "react-router-dom";

import { landingPageAPI } from "./api/LandingPageApi";

import NavBar from "./pages/Navbar";
import Hero from "./pages/Hero";
import Features from "./pages/Features";
import ContactUs from "./pages/ContactUs";
import Team from "./pages/Team";
import Footer from "./pages/Footer";

import Roi from "./pages/CV/Roi";
import Tor from "./pages/CV/Tor";
import Hjalti from "./pages/CV/Hjalti";
import AboutPage from "./pages/AboutPage/AboutPage";

import RedirectionPage from "./pages/Solutions/RedirectionPage";

function App() {
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;
  useEffect(() => {
    // Update the document title using the browser API
    document.title = `KOTA`;
    window.getComputedStyle(document.body);
    window.addEventListener("resize", () => setWidth(100));

    landingPageAPI.getInfo();
  });

  return (
    <div data-theme="dark">
      <Routes>
        <Route
          path="/"
          element={
            <div>
              <div className="bg-base-300">
                <NavBar />
                <Hero />
              </div>
              <div className="bg-base-200">
                <Features />
                <ContactUs />
              </div>
              <div className="bg-base-300">
                <Team />
              </div>
              <div>
                <Footer />
              </div>
            </div>
          }
        />

        <Route
          path="/roi"
          element={
            <div
              style={{ display: "flex", flexFlow: "column", height: "100%" }}
            >
              <div className="bg-base-300">
                <NavBar />
                <Roi />
              </div>

              <div>
                <Footer />
              </div>
            </div>
          }
        />

        <Route
          path="/tor"
          element={
            <div>
              <div className="bg-base-300">
                <NavBar />
                <Tor />
              </div>

              <div>
                <Footer />
              </div>
            </div>
          }
        />

        <Route
          path="/hjalti"
          element={
            <div>
              <div className="bg-base-300">
                <NavBar />
                <Hjalti />
              </div>

              <div>
                <Footer />
              </div>
            </div>
          }
        />

        {/* <Route
          path="/about"
          element={
            <div>
              <div className="bg-base-300">
                <NavBar />
              </div>
              <AboutPage />
              <div className="bg-base-300">
                <Team />
              </div>
              <div>
                <Footer />
              </div>
            </div>
          }
        /> */}

        <Route
          path="/solutions/:solutionName"
          element={<RedirectionPage />}
        ></Route>
      </Routes>
    </div>
  );
}

export default App;
