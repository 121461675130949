import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import AppPhone from "./AppPhone";
import reportWebVitals from "./reportWebVitals";
import { MantineProvider } from "@mantine/core";

import axios from "axios";

import { BrowserView, MobileView } from "react-device-detect";

import { HashRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

axios.defaults.baseURL = "http://localhost:5050";

root.render(
  <React.StrictMode>
    <HashRouter>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{ colorScheme: "dark" }}
      >
        <BrowserView>
          <App />
        </BrowserView>
        <MobileView>
          <App />
        </MobileView>
      </MantineProvider>
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
