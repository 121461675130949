import {
  Container,
  Grid,
  AspectRatio,
  Image,
  Text,
  Title,
  Group,
  Center,
  Box,
  Flex,
  Space,
  Stack,
  Button,
} from "@mantine/core";

import { createStyles, SimpleGrid, Icon, rem } from "@mantine/core";
import {
  IconCurrentLocation,
  IconFlame,
  IconFlag,
  IconCalendarStats,
  IconBrandLinkedin,
  IconMailQuestion,
  IconFileText,
  IconNotebook,
  IconBuildingCommunity,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { openUrlNewTab } from "../../utils/HelperFunctions";

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
  },

  item: {
    "&:hover": {
      transform: "scale(1.50)",
    },
  },
}));

export default function About(props) {
  let data = props.data;
  const { classes, theme } = useStyles();
  const navigate = useNavigate();

  const items = data.features.map((feature) => (
    <Flex
      justify="flex-start"
      gap="md"
      align="center"
      direction="row"
      key={feature.title}
    >
      <feature.icon color="white" size={rem(22)} stroke={1.5} />
      <Text color="white" fw={700}>
        {feature.title}
      </Text>
      <Text c="dimmed">{feature.description}</Text>
    </Flex>
  ));

  return (
    <div className="bg-neutral-800" sectionId={"about"}>
      <Container>
        <Grid>
          <Grid.Col xs={4}>
            <AspectRatio ratio={720 / 1080} maw={200} mx="auto">
              <Image radius="xl" src={data.photo} alt="Panda" />
            </AspectRatio>
          </Grid.Col>
          <Grid.Col xs={8}>
            <div style={{ marginTop: "15px" }}></div>
            <Title color="white">About me</Title>
            {data.aboutText}
            <Space h={10} />
            <SimpleGrid
              cols={2}
              spacing={20}
              breakpoints={[{ maxWidth: "md", cols: 1 }]}
            >
              {items}
            </SimpleGrid>
            <Space h={30}></Space>

            <Flex
              mih={50}
              gap="xl"
              justify="left"
              align="center"
              direction="row"
              wrap="wrap"
            >
              <Flex
                mih={50}
                gap="xl"
                justify="center"
                align="center"
                direction="row"
                wrap="wrap"
              >
                <a href={data.cvUrl} download="test.pdf" target="_blank">
                  <Button
                    variant="outline"
                    radius="xl"
                    uppercase
                    color="pink"
                    leftIcon={<IconFileText size="1.4rem" />}
                  >
                    Resume
                  </Button>
                </a>
                {data.linkedIn && (
                  <Button
                    variant="outline"
                    radius="xl"
                    uppercase
                    color="pink"
                    leftIcon={<IconBrandLinkedin size="1.4rem" />}
                    onClick={() => openUrlNewTab(data.linkedIn)}
                  >
                    LinkedIn
                  </Button>
                )}
                <a href={"mailto:" + data.email + "?subject=KOTA Inquiry"}>
                  <Button
                    variant="outline"
                    radius="xl"
                    uppercase
                    color="pink"
                    leftIcon={<IconMailQuestion size="1.4rem" />}
                  >
                    Contact
                  </Button>
                </a>
              </Flex>
            </Flex>
          </Grid.Col>
        </Grid>

        <Space h={30}></Space>
      </Container>
    </div>
  );
}
