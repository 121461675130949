import React, { useState } from "react";
import {
  createStyles,
  Text,
  Title,
  SimpleGrid,
  TextInput,
  Textarea,
  Button,
  Group,
  ActionIcon,
  rem,
  Container,
} from "@mantine/core";
import {
  IconBrandTwitter,
  IconBrandYoutube,
  IconBrandInstagram,
} from "@tabler/icons-react";
import { ContactIconsList } from "./ContactIcons";

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: 400,
    backgroundImage: `linear-gradient(-60deg, ${theme.colors.gray[6]} 0%, ${theme.colors.gray[8]} 100%)`,
    padding: `calc(${theme.spacing.xl} * 2.5)`,
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    color: theme.white,
    lineHeight: 1,
  },

  description: {
    color: theme.colors[theme.primaryColor][0],
    maxWidth: rem(300),

    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
    },
  },

  form: {
    backgroundColor: theme.white,
    padding: theme.spacing.xl,
    borderRadius: theme.radius.md,
    boxShadow: theme.shadows.lg,
  },

  input: {
    backgroundColor: theme.white,
    borderColor: theme.colors.gray[4],
    color: theme.black,

    "&::placeholder": {
      color: theme.colors.gray[5],
    },
  },

  inputLabel: {
    color: theme.black,
  },
}));

export default function ContactUs() {
  const { classes } = useStyles();
  const [userName, setUserName] = useState("");
  const [messageText, setMessageText] = useState("");

  return (
    <div className={classes.wrapper}>
      <Container>
        <SimpleGrid
          cols={2}
          spacing={50}
          breakpoints={[{ maxWidth: "sm", cols: 1 }]}
        >
          <div>
            <Title className={classes.title}>Contact us</Title>
            <Text className={classes.description} mt="sm" mb={30}>
              Send us a message and we will get back to you within 24 hours
            </Text>

            <ContactIconsList variant="white" />

            {/* <Group mt="xl">{icons}</Group> */}
          </div>

          <div className={classes.form}>
            {/* <TextInput
              label="Email"
              placeholder="your@email.com"
              required
              classNames={{ input: classes.input, label: classes.inputLabel }}
            /> */}
            <TextInput
              label="Name"
              placeholder="Please enter your name"
              mt="md"
              onChange={(evt) => setUserName(evt.target.value)}
              classNames={{ input: classes.input, label: classes.inputLabel }}
            />
            <Textarea
              required
              label="Your message"
              placeholder="Hi, I would like to..."
              minRows={4}
              mt="md"
              onChange={(evt) => setMessageText(evt.target.value)}
              classNames={{ input: classes.input, label: classes.inputLabel }}
            />
            <Group position="right" mt="md">
              <a
                href={
                  "mailto:" +
                  "kota.data.contact@gmail.com" +
                  "?subject=KOTA Inquiry - " +
                  userName +
                  "&cc=roidjoensen@gmail.com;hjalti.d.joensen@gmail.com;tordamj@gmail.com" +
                  "&body=" +
                  messageText
                }
              >
                <Button variant="default" style={{ color: "#87CEEB" }}>
                  Send Message
                </Button>
              </a>
            </Group>
          </div>
        </SimpleGrid>
      </Container>
    </div>
  );
}
