import React, { useEffect } from "react";
import tor from "../../static/tor.JPG";
import { Text, List } from "@mantine/core";
import {
  IconCurrentLocation,
  IconFlame,
  IconFlag,
  IconCalendarStats,
  IconNotebook,
  IconBuildingCommunity,
} from "@tabler/icons-react";
import About from "./About";
import Curricilum from "./Curricilum";

export default function Tor() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="bg-base-300">
        <About data={data} />
        <Curricilum features={features} />
      </div>
    </div>
  );
}

const data = {
  photo: tor,
  email: "tordamj@gmail.com",
  // linkedIn: "https://www.linkedin.com/in/r%C3%B3i-dam-joensen-14081699/",
  cvUrl: "https://212.60.126.221/landing_page/tor_cv",
  aboutText: (
    <Text>
      I am a highly skilled computer engineer with a Master’s degree from
      Danmarks Tekniske Universitet (DTU) with specialization in Data
      Science/Big Data. My professional experience includes software
      development, where I have worked with both legacy systems and the
      development of enterprise systems from scratch. I always apply strong
      fundamental principles of software engineering to my work to ensure
      high-quality results.
    </Text>
  ),
  features: [
    {
      icon: IconCurrentLocation,
      title: "Location:",
      description: "Copenhagen, Denmark",
    },
    {
      icon: IconCalendarStats,
      title: "Age:",
      description: "31",
    },
    {
      icon: IconFlag,
      title: "Nationality:",
      description: "Danish",
    },
    {
      icon: IconFlame,
      title: "Interests:",
      description: "Cycling, Hiking, Finance, Formula1",
    },
    {
      icon: IconNotebook,
      title: "Study:",
      description: "DTU",
    },
    {
      icon: IconBuildingCommunity,
      title: "Employment:",
      description: "KMD A/S",
    },
  ],
};

const features = {
  education: [
    {
      degree: " M.Sc. in Computer Science and Engineering",
      school: "Danmarks Tekniske Universitet (DTU) • 2018",
      masterThesis:
        "Analyzing patterns of global warming through data visualization",
    },
    {
      degree: "B.Sc in Software Engineering",
      school: "University of Faroe Island • 2015",
      masterThesis: "Predicting stock prices using machine learning",
    },
  ],
  work: [
    {
      company: "KMD A/S",
      timeline: "2022 - present",
      position: "Developer",
      description: (
        <p>
          Working as a Full Stack developer, I help develop an enterprise case
          and document handling system from the ground up. Using, the latest
          technologies and architecture, such as Azure Cloud and microservice
          architecture, my tasks include:
          <ul>
            <li>
              · Developing a new ReactJS solution to replace the older Angular
              functionality.
            </li>
            <li>· Developing a new RestAPI backend solution using .Net 6.0</li>
            <li>
              · Working on a enterprise MSSQL solution for all data storage
            </li>
            <li>
              · Integrate Azure Functions and Windows services to handle backend
              logic and data processing
            </li>
            <li>
              · Utilizing Azure Devops solutions for version control, testing,
              deployment, and collaboration.
            </li>
          </ul>
        </p>
      ),
    },
    {
      company: "Biosense Solutions ApS",
      timeline: "2021 - 2022",
      position: "Software Engineer",
      description: (
        <p>
          Worked as a C# and SQL programmer, developing frontend and backend
          features for the Microscope application. An application that processes
          large amounts of images and applies image analyzation algorithms.
          <ul>
            <li>· Implementing new UI features using C# WPF.</li>
            <li>
              · Improving response times by re-implementing and refactoring
              database and backend solutions.
            </li>
            <li>
              · Working with a team of developers and stakeholders to find new
              and improve existing features
            </li>
          </ul>
        </p>
      ),
    },
    {
      company: "Biosense Solutions ApS",
      timeline: "2018 - 2021",
      position: "Student Software Engineer",
      description: (
        <>
          Worked as a C# programmer, developing frontend features for the
          Microscope application
        </>
      ),
    },
    {
      company: "P/f Elektron",
      timeline: "2014 - 2014",
      position: "Student Software Developer",
      description: (
        <>
          Worked as a Java programmer on "Mínboks", the Faroese version of
          theDanish e-boks. I worked on the mobile version, where we used Google
          WebToolkit.
        </>
      ),
    },
    {
      company: "P/f Føroya Tele",
      timeline: "2012 - 2012",
      position: "Network Supporter",
      description: (
        <>
          Worked as customer support and troubleshooting for a
          telecommunications company.
        </>
      ),
    },
  ],

  skills: [
    "JavaScript",
    "React",
    "SQL",
    "Postgres",
    "Java",
    "C#/.Net",
    "Node.js",
    "Azure Cloud",
    "Azure DevOps",
    "Python",
    "PySpark",
    "PyTorch",
    "Pandas",
  ],
};
