import roi from "../../static/roi.jpg";
import {
  Container,
  Grid,
  Text,
  Title,
  Group,
  Stack,
  Space,
  Divider,
  List,
  Transition,
} from "@mantine/core";

import { createStyles, SimpleGrid, Icon, rem } from "@mantine/core";
import {
  IconReceiptOff,
  IconFlame,
  IconCircleDotted,
  IconFileCode,
} from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  wrapper: {
    padding: `calc(${theme.spacing.xl} * 2) ${theme.spacing.xl}`,
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(36),
    fontWeight: 900,
    lineHeight: 1.1,
    marginBottom: theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
  },
}));

export default function About({ ...props }) {
  let features = props.features;
  return (
    <div>
      <div
        className="bg-neutral-300"
        sectionId={"about"}
        style={{ paddingTop: "50px" }}
      >
        <Container>
          <Grid>
            <Grid.Col xs={4}>
              <Title
                color="black"
                style={{ textDecorationColor: "#f06595" }}
                underline
                order={2}
              >
                EDUCATION
              </Title>
            </Grid.Col>
            <Grid.Col xs={8}>
              {features.education.map((education) => {
                return (
                  <Stack spacing={0}>
                    <Text color="black" fz="xl" fw={700}>
                      {education.degree}
                    </Text>
                    <Text color="black" fz="sm">
                      {education.school}
                    </Text>
                    <Text color="black" fz="sm">
                      <Text fw={700} span>
                        Thesis: &nbsp;
                      </Text>
                      {education.masterThesis}
                    </Text>
                    <Space h={20}></Space>
                  </Stack>
                );
              })}
            </Grid.Col>
          </Grid>

          <Divider my="sm" variant="dashed"></Divider>

          <Grid>
            <Grid.Col xs={4}>
              <Title
                color="black"
                style={{ textDecorationColor: "#f06595" }}
                underline
                order={2}
              >
                Skills & Tools
              </Title>
            </Grid.Col>
            <Grid.Col xs={8}>
              <SimpleGrid
                cols={3}
                spacing="xs"
                verticalSpacing="0"
                breakpoints={[
                  { maxWidth: "62rem", cols: 3, spacing: "md" },
                  { maxWidth: "48rem", cols: 2, spacing: "sm" },
                  { maxWidth: "36rem", cols: 2, spacing: "sm" },
                ]}
              >
                {features.skills.map((skill) => {
                  return (
                    <Text color="black" fz="sm" fw={500}>
                      {skill}
                    </Text>
                  );
                })}
              </SimpleGrid>
            </Grid.Col>
          </Grid>

          <Divider my="sm" variant="dashed"></Divider>

          <Grid>
            <Grid.Col xs={4}>
              <Title
                color="black"
                style={{ textDecorationColor: "#f06595" }}
                underline
                order={2}
              >
                WORK EXPERIENCE
              </Title>
            </Grid.Col>
            <Grid.Col xs={8}>
              {features.work.map((work) => {
                return (
                  <Transition
                    mounted={true}
                    transition="fade"
                    duration={4000}
                    timingFunction="ease"
                  >
                    {(styles) => (
                      <Stack spacing={0}>
                        <Group position="apart">
                          <Text color="black" fz="xl" fw={700}>
                            {work.company}
                          </Text>
                          <Text color="black" fz="sm" fw={700}>
                            {work.timeline}
                          </Text>
                        </Group>

                        <Text color="black" fz="sm">
                          <Text fw={700} span>
                            Position: &nbsp;
                          </Text>

                          {work.position}
                        </Text>
                        <Text color="black" fz="sm">
                          {work.description}
                        </Text>
                        <Space h={20}></Space>
                      </Stack>
                    )}
                  </Transition>
                );
              })}
            </Grid.Col>
          </Grid>
        </Container>
      </div>
    </div>
  );
}
