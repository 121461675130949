import React from "react";
import { Link } from "react-router-dom";
import { createStyles, Container, SimpleGrid } from "@mantine/core";

import roi from "../static/roi.jpg";
import tor from "../static/tor.JPG";
import hjalti from "../static/hjalti.jpg";
const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: 400,
    padding: `calc(${theme.spacing.xl} * 2.5)`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function Team() {
  const { classes } = useStyles();
  const profiles = [
    {
      image: roi,
      header: "Rói Dam Joensen",
      text: "Software Engineer",
      link: "/roi",
    },
    {
      image: tor,
      header: "Tór Dam Joensen",
      text: "Software Engineer",
      link: "/tor",
    },
    {
      image: hjalti,
      header: "Hjalti Dam Joensen",
      text: "Mathematical Engineer",
      link: "/hjalti",
    },
  ];
  return (
    <div className={classes.wrapper}>
      <Container>
        <SimpleGrid
          cols={3}
          spacing={50}
          breakpoints={[
            { maxWidth: "md", cols: 2 },
            { maxWidth: "sm", cols: 1 },
          ]}
        >
          {profiles.map((profile, id) => (
            <div
              key={id}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
              className="card lg:card-side bg-base-100 shadow-xl"
            >
              <figure style={{ height: "320px", overflow: "hidden" }}>
                <img
                  src={profile.image}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderTopRightRadius: "20px",
                    borderBottomRightRadius: "20px",
                  }}
                  alt="Album"
                />
              </figure>
              <div className="card-body">
                <h2 className="card-title">{profile.header}</h2>
                <p>{profile.text}</p>
                <div className="card-actions justify-end">
                  <Link to={profile.link}>
                    <button className="btn btn-primary">Read More</button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </SimpleGrid>
      </Container>
    </div>
  );
}
