import React, { useState } from "react";
import {
  createStyles,
  Title,
  Text,
  Card,
  SimpleGrid,
  Container,
  rem,
  UnstyledButton,
  Center,
} from "@mantine/core";
import { IconCookie, IconStack3, IconChartAreaLine } from "@tabler/icons-react";

const mockdata = [
  {
    title: "Full-Stack Engineers",
    description: (
      <p>
        Our team of full stack engineers possesses a deep understanding of both
        front-end and back-end development, allowing us to create comprehensive
        software solutions. From designing intuitive user interfaces to
        implementing robust server-side functionality, our full stack approach
        ensures that every aspect of your software is built to perfection.
      </p>
    ),
    icon: IconStack3,
  },
  {
    title: "Nurturing Business Understanding to Drive Success",
    description: (
      <>
        <p>
          We believe that developing a deep understanding of your business is
          paramount to delivering impactful solutions. Our team combines
          technical expertise with a strong business acumen, ensuring that we
          grasp the intricacies and nuances of your industry, processes, and
          goals.
        </p>
        <p>
          Partner with us to leverage our deep business understanding, and
          embark on a transformative journey where technology meets your unique
          organizational goals."
        </p>
      </>
    ),
    icon: IconCookie,
  },
  {
    title: "Data Analytics Expertise",
    description: (
      <p>
        At our custom software company, we not only excel in building tailored
        software solutions but also possess advanced data analytics capabilities
        backed by our team of machine learning experts. We harness the potential
        of your data to drive informed decision-making and uncover valuable
        insights. Our data analytics services encompass data collection,
        cleansing, transformation, and visualization, enabling you to gain a
        deeper understanding of your business operations.
      </p>
    ),
    icon: IconChartAreaLine,
  },
];

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: rem(34),
    fontWeight: 900,

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(24),
    },
  },

  description: {
    maxWidth: 800,
    margin: "auto",

    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: rem(45),
      height: rem(2),
      marginTop: theme.spacing.sm,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  card: {
    border: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
    transition: "transform 150ms ease, box-shadow 150ms ease",
    "&:hover": {
      transform: "scale(1.03)",
      boxShadow: theme.shadows.md,
    },
  },

  cardTitle: {
    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: rem(45),
      height: rem(2),
      marginTop: theme.spacing.sm,
    },
  },
}));

export default function Features() {
  const { classes, theme } = useStyles();
  const [readMore, setReadMore] = useState({
    passionDriven: false,
  });

  const [state, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const features = mockdata.map((feature) => (
    <Card
      key={feature.title}
      shadow="md"
      radius="md"
      className={classes.card}
      padding="xl"
    >
      <feature.icon size={rem(50)} stroke={2} color={theme.fn.primaryColor()} />
      <Text fz="lg" fw={500} className={classes.cardTitle} mt="md">
        {feature.title}
      </Text>
      <Text fz="sm" c="dimmed" mt="sm">
        <Text lineClamp={readMore[feature.title] ? -1 : 3}>
          {feature.description}
        </Text>
      </Text>

      <Center>
        <UnstyledButton>
          <Text
            onClick={() => {
              readMore[feature.title] = !readMore[feature.title];
              forceUpdate();
            }}
            c="blue"
            ta="center"
            mt="md"
          >
            {readMore[feature.title] ? "read less" : "read more"}
          </Text>
        </UnstyledButton>
      </Center>
    </Card>
  ));

  return (
    <Container size="lg" py="xl">
      {/* <Group position="center">
          <Badge variant="filled" size="lg">
            Best company ever
          </Badge>
        </Group>
      */}
      <Title order={2} className={classes.title} ta="center" mt="sm">
        Passion-Driven Experts Pursuing Exciting Challenges
      </Title>

      <Text
        c="dimmed"
        className={classes.description}
        align="justify"
        mt="md"
        lineClamp={readMore.passionDriven ? -1 : 4}
      >
        <p>
          We are a collective of dedicated professionals who bring a wealth of
          experience from diverse sectors like finance, pharmaceuticals, and
          software houses. While we are full-time employed in our respective
          fields, our shared passion for technology and innovation has led us to
          embark on this part-time endeavor.
        </p>
        <p>
          Our team thrives on seeking new and exciting challenges in the
          software development realm. Despite our commitments to our full-time
          roles, we channel our expertise and enthusiasm into crafting bespoke
          software solutions that exceed expectations. We believe that our
          diverse professional backgrounds contribute to our ability to think
          out½side the box and bring fresh perspectives to every project.
        </p>
        <p>
          By partnering with us, you gain access to a team of professionals who
          are driven by a genuine love for what they do. We approach each
          project with enthusiasm and a hunger for pushing boundaries, ensuring
          that we deliver high-quality solutions that propel your business
          forward.
        </p>
        <p>
          Join us on this exhilarating journey, where our part-time commitment
          becomes a full-time passion for delivering exceptional software
          solutions tailored to your unique needs.
        </p>
      </Text>
      <Center>
        <UnstyledButton>
          <Text
            onClick={() => {
              readMore.passionDriven = !readMore.passionDriven;
              forceUpdate();
            }}
            c="blue"
            ta="center"
            mt="md"
          >
            {readMore.passionDriven ? "read less" : "read more"}
          </Text>
        </UnstyledButton>
      </Center>

      <SimpleGrid
        cols={3}
        spacing="xl"
        mt={50}
        breakpoints={[{ maxWidth: "md", cols: 1 }]}
      >
        {features}
      </SimpleGrid>
    </Container>
  );
}
