import React, { useEffect } from "react";
import hjalti from "../../static/hjalti.jpg";
import { Text } from "@mantine/core";
import {
  IconCurrentLocation,
  IconFlame,
  IconFlag,
  IconCalendarStats,
  IconNotebook,
  IconBuildingCommunity,
} from "@tabler/icons-react";
import About from "./About";
import Curricilum from "./Curricilum";

export default function Hjalti() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="bg-base-300">
        <About data={data} />
        <Curricilum features={features} />
      </div>
    </div>
  );
}

const data = {
  photo: hjalti,
  email: "hjalti.d.joensen@gmail.com",
  linkedIn: "https://www.linkedin.com/in/hjalti-dam-joensen-29454a209/",
  cvUrl: "https://212.60.126.221/landing_page/hjalti_cv",
  aboutText: (
    <Text>
      I am a highly skilled Full Stack Developer with a passion for leveraging
      my expertise in Mathematics and Computer Science to create impactful
      software solutions. With a Master's degree in Mathematical Modelling and
      Computation, I have a strong foundation in analyzing complex data and
      translating it into meaningful insights. My experience spans frontend and
      backend development, where I have contributed to projects that enhance
      user experiences and streamline communication between stakeholders and
      clients. I have a proven track record of designing and developing
      customizable dashboards with intuitive widgets, empowering users to make
      informed decisions.
    </Text>
  ),
  features: [
    {
      icon: IconCurrentLocation,
      title: "Location:",
      description: "Copenhagen, Denmark",
    },
    {
      icon: IconCalendarStats,
      title: "Age:",
      description: "28",
    },
    {
      icon: IconFlag,
      title: "Nationality:",
      description: "Danish",
    },
    {
      icon: IconFlame,
      title: "Interests:",
      description: "Hiking, Finance, Chess",
    },
    {
      icon: IconNotebook,
      title: "Study:",
      description: "DTU",
    },
    {
      icon: IconBuildingCommunity,
      title: "Employment:",
      description: "Saxo Bank",
    },
  ],
};

const features = {
  education: [
    {
      degree: "M.Sc. in Mathematical Modelling and Computation",
      school: "Danmarks Tekniske Universitet (DTU) • 2021",
      masterThesis: "Latent Embeddings of Brain Connectivity Data",
    },
    {
      degree: "B.Sc. in Mathematics and Technology",
      school: "Danmarks Tekniske Universitet (DTU) • 2019",
      masterThesis: "Tree search in AIs for two player games",
    },
  ],
  work: [
    {
      company: "Saxo Bank",
      timeline: "2022 - Present",
      position: "Developer",
      description: (
        <p>
          Working as a Full Stack Developer, where my primary objective is to
          help create a platform that enables stakeholders to communicate with
          clients seamlessly and conveniently via SMS, email, in-app
          notifications, and other channels. Tasks include:
          <ul>
            <li>· Working in frontend using JS React.</li>
            <li>· Working in backend using FastAPI, Flask and Databricks.</li>
            <li>· Working with Kafka pipelines.</li>
            <li>
              · Creating front- and backend tests using cypress and pytest
              respectively.
            </li>
            <li>
              · Communicate with project managers, team members, and other
              stakeholders, providing regular updates and seeking feedback.
            </li>
          </ul>
        </p>
      ),
    },
    {
      company: "Inuatek A/S",
      timeline: "2021 - 2022",
      position: "Developer",
      description: (
        <>
          Frontend Developer, where I designed and developed dashboard
          functionalities for a web application. My primary responsibility was
          to create a variety of widgets that users could choose from to
          customize their dashboard and improve their overall user experience.
          Tasks include:
          <ul>
            <li>· Working in frontend using JS React.</li>
            <li>
              · Designing dashboards for useres, with widges I had newly
              created.
            </li>
            <li>
              · Engaging in discussions with stakeholders to understand their
              requirements and determine optimal solutions for addressing their
              needs.
            </li>
          </ul>
        </>
      ),
    },
    {
      company: "Danmarks Tekniske Universitet (DTU)",
      timeline: "2021",
      position: "Teaching Assistant",
      description: (
        <>
          Teaching assistant in the M.Sc. course Database Systems.
          <br />
          <br />
          Course curriculum:
          <ul>
            <li>· The relational model and query language SQL.</li>
            <li>· Relational algebra and calculus base calculations.</li>
            <li>· Entity-Relationship diagrams and relational schemas.</li>
            <li>· Features of good relational designs and normal forms.</li>
            <li>· Efficient database implementations.</li>
          </ul>
        </>
      ),
    },
    {
      company: "Leo Pharma",
      timeline: "2019 - 2021",
      position: "Student Worker",
      description: (
        <>
          Full stack developer, where I worked on a platform for Biologist,
          Biological Technicians etc., where they would be able to e.g.
          visualize data distributions, molecular networks, relationships among
          data groups.
          <br />
          <br />I worked with Django Rest framework in the backend:
          <ul>
            <li>· Implementing APIs.</li>
            <li>· Fetching data from external APIs.</li>
            <li>
              · Creating task queues and dedicated worker processes that
              constantly monitor task queues.
            </li>
            <li>· Synthetic data generators for testing application.</li>
          </ul>
          <br />I worked with JavaSricpt using React framework in the frontend:
          <ul>
            <li>· Data visualisations.</li>
            <li>· Data summary pages. </li>
            <li>· User friendly data generation.</li>
          </ul>
        </>
      ),
    },
  ],

  skills: [
    "Python",
    "JavaScript",
    "React",
    "SQL",
    "Java",
    "R",
    "Flask",
    "Node.js",
    "FastAPI",
    "Kafka",
    "Databricks",
    "F#",
    "Docker",
    "SAS",
    "C",
    "MATLAB",
    "Django REST framework",
  ],
};
