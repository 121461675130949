import React from "react";

import { useParams } from "react-router-dom";

export default function RedirectionPage({ ...props }) {
  let params = useParams();

  return (
    <div>
      <h1>{params.solutionName}</h1>
      <iframe
        src={"https://212.60.126.221/" + params.solutionName}
        style={{
          position: "fixed",
          top: "0px",
          eft: 0,
          bottom: 0,
          right: 0,
          width: "100%",
          height: "100%",
          border: "none",
          margin: 0,
          padding: 0,
          overflow: "hidden",
          zIndex: 999999,
        }}
      />
    </div>
  );
}

/*
 */
